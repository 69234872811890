import "../App.css";

function Partners() {
  return (
    <>
      <h2 className="ponudah2 partner">Partners</h2>
      <div className="container marketing">
        <div className="row">
          <div
            className="col-lg-4 partners2"
            data-aos="fade-up"
            data-aos-duration={1000}
            data-aos-once="false"
          >
            <title>Siemens</title>
            <a href="https://www.siemens.com/">
              <img
                src="./images/siemens.png"
                alt="Siemens"
                className="part-logo part-p"
                id="Siemens"
              />
            </a>
          </div>
          <div
            className="col-lg-4 partners2"
            data-aos="fade-up"
            data-aos-duration={1000}
            data-aos-once="false"
          >
            <title>Ehonet</title>
            <a href="https://ehonet.hr/">
              <img
                src="./images/ehonetlight.png"
                alt="Ehonet"
                className="part-logo"
                id="ehonet"
              />
            </a>
          </div>
          <div
            className="col-lg-4 partners2"
            data-aos="fade-up"
            data-aos-duration={1000}
            data-aos-once="false"
          >
            <title>Held</title>
            <a href="https://held.hr/">
              <img
                src="./images/held_white.png"
                alt="Held.hr"
                className="part-logo part-p"
                id="held"
              />
            </a>
          </div>
          <div
            className="col-lg-4 partners2"
            data-aos="fade-up"
            data-aos-duration={1000}
            data-aos-once="false"
          >
            <title>Cito</title>
            <a href="https://www.cito.hr/hr/">
              <img
                src="./images/cito2.png"
                alt="cito"
                className="part-logo part-p"
              />
            </a>
          </div>
          <div
            className="col-lg-4 partners2"
            data-aos="fade-up"
            data-aos-duration={1000}
            data-aos-once="false"
          >
            <title>Dems</title>
            <a href="https://dems.hr/index.php/en/">
              <img
                src="./images/dems.png"
                alt="Dems d.o.o"
                className="part-logo part-p"
                id="dems"
              />
            </a>
          </div>
          <div
            className="col-lg-4 partners2"
            data-aos="fade-up"
            data-aos-duration={1000}
            data-aos-once="false"
          >
            <title>Studenac</title>
            <a href="https://www.studenac.hr/en/">
              <img
                src="./images/studenac.png"
                alt="Addiko Bank"
                className="part-logo part-p"
                id="addiko"
              />
            </a>
          </div>
          <div
            className="col-lg-4 partners2"
            data-aos="fade-up"
            data-aos-duration={1000}
            data-aos-once="false"
          >
            <title>Poljak</title>
            <a href="http://www.poljak.hr/">
              <img
                src="./images/poljak.png"
                alt="Poljak d.o.o"
                className="part-logo part-p"
              />
            </a>
          </div>
          <div
            className="col-lg-4 partners2"
            data-aos="fade-up"
            data-aos-duration={1000}
            data-aos-once="false"
          >
            <title>Imex Bank</title>
            <a href="https://www.imexbanka.hr/">
              <img
                src="./images/imex.png"
                alt="Imex Bank"
                className="part-logo part-p"
                id="Imex Bank"
              />
            </a>
          </div>
          <div
            className="col-lg-4 partners2"
            data-aos="fade-up"
            data-aos-duration={1000}
            data-aos-once="false"
          >
            <title>Zalloo</title>
            <a href="https://www.zalloo.app/">
              <img
                src="./images/zalloo.png"
                alt="Zalloo Tours"
                className="part-logo part-p"
                id="Zalloo"
              />
            </a>
          </div>
        </div>
      </div>
      <div className="footer2">
        <img
          src="./images/telenet2.png"
          className="telenet-footer"
          alt="Telenet"
        />

        <div className="copiright">
          <p>© Telenet, 2023 All rights reserved.</p>
          <div>
            <a
              className="footersocials"
              href="https://www.linkedin.com/company/telenet-hr"
            >
              <img
                src="./images/in.png"
                alt="LinkedIn"
                width={25}
                height={25}
              />
            </a>

            <a
              className="footersocials"
              href="https://www.instagram.com/telenet.hr/"
            >
              <img
                src={"./images/insta.png"}
                alt="instagram"
                width={25}
                height={25}
              />
            </a>
          </div>
        </div>
      </div>
      <div className="footer3"></div>
    </>
  );
}

export default Partners;
