import React from "react";
import Contact from "../components/Contact";

function ContactScreen() {
  return (
    <div className="contactScreen">
      <h2 className="contactScreenHeader">Reach out!</h2>
      <div className="contactScreenArea">
        <div className="row featurette">
          <div className="col-md-7">
            <h2 className="featurette-heading fw-normal lh-1">
              {/* Reach Out <span className="text-muted" /> */}
            </h2>
            <div
              className="lead"
              // data-aos="fade-right"
              // data-aos-duration={1200}
              // data-aos-once="false"
            >
              <h4
                data-aos="fade-right"
                data-aos-duration={800}
                data-aos-once="false"
              >
                Don't Hesitate To Contact Us!{" "}
              </h4>{" "}
              <br />
              <div
                className="contactText"
                data-aos="fade-right"
                data-aos-duration={1100}
                data-aos-once="false"
              >
                <img
                  src="./images/phoneBlue.png"
                  alt="email"
                  width={25}
                  className="conactIcon"
                />
                +385-95-851-6594
              </div>
              <br />
              <div
                data-aos="fade-right"
                data-aos-duration={1400}
                data-aos-once="false"
              >
                <img src="./images/emailBlue.png" alt="email" width={25} />
                <a className="lsmailContact" href="mailto:info@telenet.agency">
                  info@telenet.agency
                </a>
              </div>
              <br />
              <div
                data-aos="fade-right"
                data-aos-duration={1700}
                data-aos-once="false"
              >
                <img
                  src="./images/locationBlue.png"
                  alt="email"
                  width={25}
                  className="conactIcon"
                />
                Matice Hrvatske 1, Split, Croatia <br />
              </div>
              {/* Find us on */}
              {/* <a
                href="https://www.linkedin.com/company/telenet-hr"
                className="in"
              >
                <img
                  className="in"
                  src="./images/in.png"
                  width={20}
                  alt="linked in"
                />
              </a>
              or
              <a href="https://www.instagram.com/telenet.hr/" className="in">
                <img
                  className="instagram"
                  src="./images/insta.png"
                  width={15}
                  alt="instagram"
                />
              </a> */}
            </div>
          </div>
          <div className="col-md-5">
            <div className="reachoutarea">
              <Contact />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactScreen;
