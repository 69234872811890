import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import { animateScroll as scroll } from "react-scroll";
import lottie from "lottie-web";
import { defineElement } from "lord-icon-element";
import { useState, useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

import ProductScreen from "./screens/ProductScreen";
import LoadingScreen from "./screens/loadingScreem";
import LandingPage from "./screens/landingPage";
import Tours from "./screens/Tours";
import ScrollUpButton from "./components/ScrollUpButton";
import ContactScreen from "./screens/contactScreen";
import AboutScreen from "./screens/AboutScreen";
import ZallooTour from "./screens/ZallooTour";
import Navbar from "./components/Navbar";

defineElement(lottie.loadAnimation);

function Main() {
  const location = useLocation();

  const ScrollToElement = () => {
    useEffect(() => {
      scroll.scrollToTop();
    }, [location.pathname]);

    return null;
  };

  return (
    <>
      <ScrollToElement />
      {location.pathname !== "/villaBiancomar" &&
        location.pathname !== "/villaTrogir" &&
        location.pathname !== "/maris" && <Navbar />}

      <Routes>
        <Route exact path="/" element={<LandingPage />} />
        <Route path="/about" element={<AboutScreen />} />
        <Route path="/contact" element={<ContactScreen />} />
        <Route path="/products" element={<ProductScreen />} />
        <Route path="/tours" element={<Tours />} />
        {/* <Route path="/zallooTour" element={<ZallooTour />} /> */}
        <Route path="/villaBiancomar" element={<ZallooTour />} />
        <Route path="/villaTrogir" element={<ZallooTour />} />
        <Route path="/maris" element={<ZallooTour />} />
      </Routes>

      <ScrollUpButton smooth />
    </>
  );
}

function App() {
  const [Loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  }, []);

  useEffect(() => {
    AOS.init();
  }, []);

  return <Router>{Loading ? <LoadingScreen /> : <Main />}</Router>;
}

export default App;

// import "./App.css";
// import {
//   BrowserRouter as Router,
//   Route,
//   Routes,
//   useLocation,
// } from "react-router-dom";
// import { animateScroll as scroll } from "react-scroll";
// import lottie from "lottie-web";
// import { defineElement } from "lord-icon-element";
// import { useState, useEffect } from "react";
// import AOS from "aos";
// import "aos/dist/aos.css";
// import ProductScreen from "./screens/ProductScreen";
// import LoadingScreen from "./screens/loadingScreem";
// import LandingPage from "./screens/landingPage";
// import Tours from "./screens/Tours";
// import ScrollUpButton from "./components/ScrollUpButton";
// import ContactScreen from "./screens/contactScreen";
// import AboutScreen from "./screens/AboutScreen";
// import ZallooTour from "./screens/ZallooTour";
// import Navbar from "./components/Navbar";

// defineElement(lottie.loadAnimation);

// function App() {
//   const [Loading, setLoading] = useState(false);
//   const location = useLocation();

//   // const [isActive, setIsActive] = useState(false);
//   // const handleClick = () => {
//   //   setIsActive((current) => !current);
//   // };

//   // const handleClickHome = () => {
//   //   setIsActive(false);
//   // };

//   useEffect(() => {
//     setLoading(true);
//     setTimeout(() => {
//       setLoading(false);
//     }, 1500);
//   }, []);

//   useEffect(() => {
//     AOS.init();
//   }, []);

//   const ScrollToElement = () => {
//     useEffect(() => {
//       scroll.scrollToTop();
//     }, []);

//     return null;
//   };

//   return (
//     <>
//       <Router>
//         <ScrollToElement />
//         {Loading ? (
//           <LoadingScreen />
//         ) : (
//           <>
//             {location.pathname !== "/zallooTour" && <Navbar />}

//             <Routes>
//               <Route exact path="/" element={<LandingPage />} />
//               <Route path="/about" element={<AboutScreen />} />
//               <Route path="/contact" element={<ContactScreen />} />
//               <Route path="/products" element={<ProductScreen />} />
//               <Route path="/tours" element={<Tours />} />
//               <Route path="/zallooTour" element={<ZallooTour />} />
//             </Routes>

//             <ScrollUpButton smooth />
//           </>
//         )}
//       </Router>
//     </>
//   );
// }

// export default App;
