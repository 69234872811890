import React, { useState, useEffect } from "react";
import "../App.css";

const ScrollUpButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  const handleScroll = () => {
    const scrollTop = window.pageYOffset;
    if (scrollTop > 200) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setIsVisible(false);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const styles = {
    position: "fixed",
    bottom: "30px",
    right: "30px",
    backgroundColor: "#fff",
    color: "#fff",
    borderRadius: "20%",
    width: "40px",
    height: "40px",
    display: isVisible ? "flex" : "none",
  };

  return (
    <div style={styles} onClick={scrollToTop} className="ScrollUpButtonBox">
      <img
        src="./images/UP.png"
        alt="Scroll Up"
        className="ScrollUpButtonArrow"
      />
      {/* <i className="fa fa-chevron-up"></i> */}
    </div>
  );
};

export default ScrollUpButton;
