import React from "react";
// import Contact from "../components/Contact";

import { BrowserRouter as Router, Link } from "react-router-dom";

function AboutScreen() {
  const isMobile = window.innerWidth < 768;
  return (
    <>
      <div className="contactScreenArea">
        <div className="row featurette">
          <div className="row featurette">
            <div className="col-md-7">
              <h2 className="aboutHeading">A little bit more about us</h2>
              <p
                className="lead"
                data-aos="fade-up"
                data-aos-duration={1500}
                data-aos-once="false"
              >
                We are a telecommunications, network, and software company based
                in Split, Croatia. Since our establishment in 2001, we have been
                dedicated to providing a range of services to cater to our
                clients' needs. From web design and development to system
                configuration, QA testing, VoIP solutions, network support,
                video surveillance solutions, and outsourcing services, we offer
                comprehensive telecommunications services.
                <br />
                <br />
                At Telenet, we are committed to delivering exceptional
                telecommunications services, backed by our expertise, technical
                proficiency, and customer-oriented approach. We strive to exceed
                our clients' expectations and provide them with reliable and
                innovative solutions that drive their success.
              </p>
            </div>
            <div className="col-md-5 aboutLogo">
              {isMobile ? (
                <img
                  className="telenetHeader2"
                  // src="./images/101-4.svg"
                  src="./images/10-2.png"
                  alt="Telenet"
                  width="100%"
                  // height="100%"
                  xmlns="http://www.w3.org/2000/svg"
                  // role="img"
                  aria-label="Placeholder: 500x500"
                  preserveAspectRatio="xMidYMid slice"
                  focusable="false"
                  data-aos="fade-right"
                  data-aos-duration={700}
                  data-aos-once="false"
                />
              ) : (
                <img
                  className="telenetHeader2"
                  // src="./images/101-4.svg"
                  src="./images/10-2.png"
                  alt="Telenet"
                  width="100%"
                  // height="100%"
                  xmlns="http://www.w3.org/2000/svg"
                  // role="img"
                  aria-label="Placeholder: 500x500"
                  preserveAspectRatio="xMidYMid slice"
                  focusable="false"
                  data-aos="fade-right"
                  data-aos-duration={700}
                  data-aos-once="false"
                />
              )}
            </div>
          </div>
          <hr className="featurette-divider" />
          <div className="row featurette">
            <div className="col-md-7 order-md-2">
              <h2 className="aboutHeading">Where are we?</h2>
              <p
                className="lead"
                data-aos="fade-right"
                data-aos-duration={1500}
                data-aos-once="false"
              >
                Telenet proudly calls Split, Croatia, it's home, a city where
                the company's journey began. From this vibrant coastal location,
                Telenet has grown into a trusted provider of telecommunication
                and software development services, serving clients locally and
                internationally.
                <br />
                Our team works closely with you to understand your needs and
                create a customized solution that will help you achieve your
                business goals.
              </p>
              <div
              // data-aos="fade-right"
              // data-aos-duration={1500}
              // data-aos-once="false"
              >
                <Link
                  className="btn btn-lg btn-primary btn-landing btnGradient findOutBtn aboutbtnMargin"
                  to="/contact"
                >
                  Get in touch
                </Link>
              </div>
            </div>
            <div className="col-md-5 order-md-1">
              {isMobile ? (
                <img
                  className="telenetHeader2"
                  src="./images/102-4.png"
                  // src="./images/555.png"
                  alt="Web Design"
                  width="100%"
                  // height="100%"
                  xmlns="http://www.w3.org/2000/svg"
                  // role="img"
                  aria-label="Placeholder: 500x500"
                  preserveAspectRatio="xMidYMid slice"
                  focusable="false"
                  data-aos="fade-right"
                  data-aos-duration={1000}
                  data-aos-once="false"
                />
              ) : (
                <img
                  className="telenetHeader2"
                  src="./images/102-4.png"
                  // src="./images/555.png"
                  alt="Web Design"
                  width="100%"
                  // height="100%"
                  xmlns="http://www.w3.org/2000/svg"
                  // role="img"
                  aria-label="Placeholder: 500x500"
                  preserveAspectRatio="xMidYMid slice"
                  focusable="false"
                  data-aos="fade-right"
                  data-aos-duration={1000}
                  data-aos-once="false"
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="footer3"></div>
    </>
  );
}

export default AboutScreen;
