// Navbar.js
import { Link } from "react-router-dom";
import { useState } from "react";

function Navbar() {
  const [isActive, setIsActive] = useState(false);

  const handleClick = () => {
    setIsActive((current) => !current);
  };

  const handleClickHome = () => {
    setIsActive(false);
  };

  return (
    <nav className="navbar navbar-expand-md navbar-dark fixed-top bg-dark">
      <div className="container-fluid">
        <Link className="navbar-brand" href="#home" to="/">
          <img
            id="headerlogo"
            className="telenetlogo10"
            src="./images/10-2.png"
            height={60}
            alt="telenet"
            onClick={handleClickHome}
          />
        </Link>
        <div className="menu_button_container">
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarCollapse"
            aria-controls="navbarCollapse"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <input id="menu-toggle" type="checkbox" />
            <label className="menu-button-container" htmlFor="menu-toggle">
              <img
                src="./images/navbarMenu.png"
                height={45}
                alt="navbarMenu"
                onClick={handleClick}
              />
            </label>
          </button>
        </div>
        <div
          className="collapse navbar-collapse"
          id="navbarCollapse"
          style={{
            display: isActive ? "" : "none",
          }}
        >
          <ul className="navbar-nav me-auto mb-2 mb-md-0">
            <li className="nav-item">
              <Link
                className="nav-link active2"
                aria-current="page"
                // href="#home"
                data-aos="fade-down"
                data-aos-duration={1000}
                data-aos-once="false"
                to="/"
                onClick={handleClick}
                // onClick={() => homeScreenHandler()}
              >
                Home
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className="nav-link"
                // href="#about"
                data-aos="fade-down"
                data-aos-duration={1200}
                data-aos-once="false"
                to="/about"
                onClick={handleClick}
              >
                About
              </Link>
            </li>

            <li className="nav-item">
              <Link
                className="nav-link"
                data-aos="fade-down"
                data-aos-duration={1400}
                data-aos-once="false"
                // href="#contact"
                to="/contact"
                onClick={handleClick}
                // onClick={() => homeScreenHandler()}
              >
                Contact
              </Link>
            </li>

            <li className="nav-item">
              <Link
                // href="/#Products"
                className="nav-link"
                data-aos="fade-down"
                data-aos-duration={1600}
                data-aos-once="false"
                to="/products"
                onClick={handleClick}

                // onClick={() => productScreenHandler()}
              >
                Products
              </Link>
            </li>

            <li className="nav-item">
              <Link
                // href="/#360Tours"
                className="nav-link"
                data-aos="fade-down"
                data-aos-duration={1800}
                data-aos-once="false"
                to="/tours"
                onClick={handleClick}
                // onClick={() => setTours(true)}
                // onClick={() => toursScreenHandler()}
              >
                360° Tours
              </Link>
            </li>

            <li className="nav-social-item">
              <a
                className="navbarsocials"
                href="https://www.linkedin.com/company/telenet-hr"
              >
                <img
                  className="nav-social-item-logo"
                  src="./images/in.png"
                  alt="LinkedIn"
                  width={20}
                  height={20}
                  data-aos="fade-down"
                  data-aos-duration={2000}
                  data-aos-once="false"
                />
              </a>
            </li>
            <li className="nav-social-item">
              <a
                className="navbarsocials"
                href="https://www.instagram.com/telenet.hr/"
              >
                <img
                  src={"./images/insta.png"}
                  className="nav-social-item-logo"
                  alt="instagram"
                  width={20}
                  height={20}
                  data-aos="fade-down"
                  data-aos-duration={2200}
                  data-aos-once="false"
                />
              </a>
            </li>
          </ul>
          <div className="mobileNavbarSocials">
            <div>
              <a
                className="footersocials"
                href="https://www.linkedin.com/company/telenet-hr"
              >
                <img
                  src="./images/in.png"
                  alt="LinkedIn"
                  width={20}
                  height={20}
                />
              </a>

              <a
                className="footersocials"
                href="https://www.instagram.com/telenet.hr/"
              >
                <img
                  src={"./images/insta.png"}
                  alt="instagram"
                  width={20}
                  height={20}
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
