import "../App.css";

const isMobile = window.innerWidth < 768;

const OurWork = () => {
  return (
    <>
      <h2 className="featurette-heading fw-normal lh-1 ourWorkH2">Our Work:</h2>
      <div className="row featurette ourWorkRow">
        <div
          className="col-md-5-ourwork order-md-1 ourWorkBox"
          focusable="false"
          data-aos="fade-up"
          data-aos-duration={1000}
          data-aos-once="false"
        >
          {isMobile ? (
            <img
              src="./images/mobile20.webp"
              alt="Telenet 8x8 VoIP Configuration"
              className="ourWorkImg"
            />
          ) : (
            <img
              src="./images/20.webp"
              alt="Telenet 8x8 VoIP Configuration"
              className="ourWorkImg"
            />
          )}

          {/* <img
            src="./images/20.webp"
            alt="Telenet 8x8 VoIP Configuration"
            className="ourWorkImg"
          /> */}
          <h3 className=" lh-1 ourWorkH3">
            Configuration of <span className="ourWork8x8">8x8</span> VoIP system
            in partnership with DEMS d.o.o
          </h3>
          <p className="ourWorkText">
            Our work included configuration, remote troubleshooting support,
            maintaining communication with 8x8 customers and other team members,
            and on-site deployment of various 8x8 VoIP solutions. We were
            responsible for ensuring that 8x8's communication services were
            tailored to meet the specific needs of their clients and that they
            functioned seamlessly. Our expertise allowed us to provide top-notch
            support to ensure that clients could get the most out of the
            services. Overall, our work as an SDC provider for 8x8 aimed to
            provide reliable, high-quality communication solutions to their
            clients.
          </p>
        </div>
        <div
          className="col-md-5-ourwork order-md-1 ourWorkBox"
          focusable="false"
          data-aos="fade-up"
          data-aos-duration={1000}
          data-aos-once="false"
        >
          {isMobile ? (
            <img
              src="./images/mobile22.webp"
              alt="Telenet AutoNino Web-Shop"
              className="ourWorkImg"
            />
          ) : (
            <img
              src="./images/22.webp"
              alt="Telenet AutoNino Web-Shop"
              className="ourWorkImg"
            />
          )}
          {/* <img
            src="./images/22.webp"
            alt="Telenet AutoNino Web-Shop"
            className="ourWorkImg"
          /> */}
          <h3 className=" lh-1 ourWorkH3">AutoNino Web-Shop</h3>
          <p className="ourWorkText">
            Our work involved configuring and deploying a web-shop for a client
            selling automotive parts and services. The web-shop was integrated
            with payment and shipping systems, allowing customers to easily
            purchase products online. Additionally, the web-shop was integrated
            to calculate changes in car performance after stage tuning of cars,
            providing customers with accurate information on the performance
            upgrades available. The successful deployment of the web-shop
            provides the client with an effective platform for selling car parts
            and provides customers with a convenient and informative shopping
            experience.
          </p>
        </div>
      </div>
      <div className="row featurette ourWorkRow">
        <div
          className="col-md-5-ourwork order-md-1 ourWorkBox"
          focusable="false"
          data-aos="fade-up"
          data-aos-duration={1000}
          data-aos-once="false"
        >
          {isMobile ? (
            <img
              src="./images/mobile21.webp"
              alt="Telenet Contact Center"
              className="ourWorkImg"
            />
          ) : (
            <img
              src="./images/21.webp"
              alt="Telenet Contact Center"
              className="ourWorkImg"
            />
          )}
          {/* <img
            src="./images/21.webp"
            alt="Telenet Contact Center"
            className="ourWorkImg"
          /> */}
          <h3 className=" lh-1 ourWorkH3">
            Implementation of <span className="ourWorkPDTC">PDTC</span> Contact
            Center For local Fire Departmamt
          </h3>
          <p className="ourWorkText">
            PDTC is a Simple, Flexible, and Secure multiline communication
            client for demanding environments that require a 24/7/365 solution
            for time-critical decision processes that suits our local Fire
            department perfectly. PDTC is a high-end SIP-based turret available
            as a soft client application for the Windows operating system and as
            a PT15 hardware appliance. PDTC addresses the needs of several
            categories of critical users within an organization. Our work
            consisted of deploying the PDTC Contact Center per customer's
            requirements, staff training & installation of additional IP PBX,
            backup PBX and dedicated physical handsets to work in co-operation
          </p>
        </div>
        <div
          className="col-md-5-ourwork order-md-1 ourWorkBox"
          focusable="false"
          data-aos="fade-up"
          data-aos-duration={1000}
          data-aos-once="false"
        >
          {isMobile ? (
            <img
              src="./images/mobile23.webp"
              alt="Telenet Network Infrastructure"
              className="ourWorkImg"
            />
          ) : (
            <img
              src="./images/23.webp"
              alt="Telenet Network Infrastructure"
              className="ourWorkImg"
            />
          )}
          {/* <img
            src="./images/23.webp"
            alt="Telenet Network Infrastructure"
            className="ourWorkImg"
          /> */}
          <h3 className=" lh-1 ourWorkH3">Network Infrastructure</h3>
          <p className="ourWorkText">
            Our work involved the installation, support, and migration of local
            network infrastructure for{" "}
            <span className="ourWorkAddiko">Addiko Bank</span> in Croatia's
            Dalmatia region. This included setting up routers, network switches,
            and other necessary hardware to ensure that the bank's computer
            systems could communicate with each other and with the internet
            securely and reliably. The successful installation of this network
            infrastructure is critical to the bank's ability to provide its
            services to customers efficiently and securely.
          </p>
        </div>
      </div>
    </>
  );
};

export default OurWork;
