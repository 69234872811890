import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";

function Contact() {
  const [emailValid, setEmailValid] = useState(true);

  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const handleEmailChange = (e) => {
    const email = e.target.value;
    setIsUserTypingEmail(email !== "");
    setEmailValid(validateEmail(email) || email === "");
  };

  const [isUserTypingName, setIsUserTypingName] = useState(false);
  const [isUserTypingEmail, setIsUserTypingEmail] = useState(false);
  const form = React.createRef();

  // const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_hpkb3tc",
        "template_bagiirl",
        form.current,
        "2g08Vuu94syIwpraO"
      )
      .then(
        (result) => {
          console.log(result.text);
          console.log("message sent");
          alert("Your message has been sent!");
          form.current.reset(); // Reset the form fields
          setIsUserTypingName(false); // Reset the state
          setIsUserTypingEmail(false); // Reset the state
          setEmailValid(true); // Reset the email validity state
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <form ref={form} onSubmit={sendEmail} className="ContactArea">
      <h5 className="formHeading">Or send us a message right now:</h5>

      <div className="form-group">
        {isUserTypingName || (
          <img src="./images/user.png" alt="user" className="contactIcon" />
        )}
        <input
          type="text"
          name="user_name"
          id="user_name"
          className="form-control2"
          placeholder="      Name"
          onChange={(e) => setIsUserTypingName(e.target.value !== "")}
          required
        />
      </div>

      <div className="form-group">
        {isUserTypingEmail || (
          <img src="./images/email.png" alt="email" className="contactIcon" />
        )}
        <input
          type="email"
          name="user_email"
          id="user_email"
          className="form-control2"
          placeholder="       Email"
          onChange={handleEmailChange}
          required
          // onChange={(e) => setIsUserTypingName(e.target.value !== "")}
        />
        {!emailValid && <div className="erroremailMessage">Invalid email</div>}
      </div>

      <div className="form-group">
        <textarea
          name="message"
          id="message"
          className="form-control2"
          placeholder="Your message"
          style={{ height: "150px", resize: "vertical" }}
          required
        />
      </div>

      <input
        type="submit"
        value="Send"
        className="btn  contactFormBtn btn-primary"
        onChange={(e) => setIsUserTypingName(e.target.value !== "")}
      />
    </form>
  );
}

export default Contact;
