import "../App.css";
import Documentation from "../components/documentation";
import Partners from "../components/partners";

function CroComponent() {
  return (
    <main>
      <h2 className="ponudah2">Ponuda Telekomunikacijske Opreme</h2>
      <div className="container marketing">
        <div className="row">
          <div className="col-lg-4">
            <img
              src="./images/ponuda16.png"
              alt="Telecommunications"
              className="ponuda"
            />
          </div>
          <div className="col-lg-4">
            <img
              src="./images/ponuda17.png"
              alt="Telecommunications"
              className="ponuda"
            />
          </div>
          <div className="col-lg-4">
            <img
              src="./images/ponuda18.png"
              alt="Telecommunications"
              className="ponuda"
            />
          </div>
          <div className="col-lg-4">
            <img
              src="./images/ponuda1.png"
              alt="Telecommunications"
              className="ponuda"
            />
          </div>
          <div className="col-lg-4">
            <title>Placeholder</title>

            <img
              src="./images/ponuda2.png"
              alt="Telecommunications"
              className="ponuda"
            />
          </div>
          <div className="col-lg-4">
            <title>Placeholder</title>

            <img
              src="./images/ponuda3.png"
              alt="Telecommunications"
              className="ponuda"
            />
          </div>
          <div className="col-lg-4">
            <title>Placeholder</title>

            <img
              src="./images/ponuda4.png"
              alt="Telecommunications"
              className="ponuda"
            />
          </div>
          <div className="col-lg-4">
            <title>Placeholder</title>

            <img
              src="./images/ponuda5.png"
              alt="Telecommunications"
              className="ponuda"
            />
          </div>
          <div className="col-lg-4">
            <title>Placeholder</title>

            <img
              src="./images/ponuda6.png"
              alt="Telecommunications"
              className="ponuda"
            />
          </div>
          <div className="col-lg-4">
            <title>Placeholder</title>

            <img
              src="./images/ponuda7.png"
              alt="Telecommunications"
              className="ponuda"
            />
          </div>
          <div className="col-lg-4">
            <title>Placeholder</title>

            <img
              src="./images/ponuda8.png"
              alt="Telecommunications"
              className="ponuda"
            />
          </div>
          <div className="col-lg-4">
            <title>Placeholder</title>

            <img
              src="./images/ponuda9.png"
              alt="Telecommunications"
              className="ponuda"
            />
          </div>
          <div className="col-lg-4">
            <title>Placeholder</title>

            <img
              src="./images/ponuda10.png"
              alt="Telecommunications"
              className="ponuda"
            />
          </div>
          <div className="col-lg-4">
            <title>Placeholder</title>

            <img
              src="./images/ponuda11.png"
              alt="Telecommunications"
              className="ponuda"
            />
          </div>
          <div className="col-lg-4">
            <title>Placeholder</title>

            <img
              src="./images/ponuda12.png"
              alt="Telecommunications"
              className="ponuda"
            />
          </div>
          <div className="col-lg-4">
            <title>Placeholder</title>

            <img
              src="./images/ponuda13.png"
              alt="Telecommunications"
              className="ponuda"
            />
          </div>
          <div className="col-lg-4">
            <title>Placeholder</title>

            <img
              src="./images/ponuda14.png"
              alt="Telecommunications"
              className="ponuda"
            />
          </div>
          <div className="col-lg-4">
            <title>Placeholder</title>

            <img
              src="./images/ponuda15.png"
              alt="Telecommunications"
              className="ponuda"
            />
          </div>
        </div>
        <div className="documentationSection">
          <Documentation />
        </div>
      </div>
      <section className="ponuda2 lead" id="uvijeti">
        <h2 className="ponudah2">Uvjeti</h2>
        <p>
          Rok isporuke: <br />
          7 radnih dana od primitka narudžbe. <br />
          <br />
          Uvjeti isporuke: <br />
          Uvjeti isporuke odnose se na paritet FCO kupac. <br />
          <br />
          Plaćanje: <br />
          30 dana nakon isporuke. <br />
          <br />
          Uvjeti garancije: <br />
          Garancija uključuje popravak ili zamjenu svih dijelova u tijeku 1
          godine. Garancija isključuje zamjenu dijelova oštećenih dolaskom
          previsokog napona po javnoj električnoj i telefonskoj mreži. <br />
          <br />
        </p>
        <p className="ponuda-footer-info">
          U nadi da smo doprinijeli poboljšanju kvalitete komuniciranja unutar
          Vašeg poduzeća, a time i efikasnosti poslovanja, ostajemo otvoreni za
          daljnje kontakte. <br />
          <br />
        </p>
        <p>
          Kontaktiraje nas: <br />
          +385-91-540-3544 <br />
          <a className="lsmail" href="mailto:telenet.split@gmail.com">
            telenet.split@gmail.com
          </a>
        </p>
      </section>
      <Partners />
    </main>
  );
}

export default CroComponent;
