import React from "react";
import { useLocation } from "react-router-dom";
import { useState } from "react";

import { BrowserRouter as Router, Link } from "react-router-dom";

const ZallooTour = () => {
  const location = useLocation();
  const iframeUrl = location.state?.iframeUrl;
  const [isActive, setIsActive] = useState(false);

  const handleClickHome = () => {
    setIsActive(false);
  };

  return (
    <div className="ZallooTourScreen">
      <div className="ZallooTourNavbar">
        <Link to="/tours" className="backButton">
          <img
            className="backIcon"
            src="./images/back.png"
            alt="Back"
            width={30}
          />
          <p className="backButtonText">Go Back</p>
        </Link>
        <Link className="toursTelenetBackLogoArea" href="#home" to="/">
          <img
            id="headerlogo"
            className="toursTelenetBackLogo"
            src="./images/telenet2.png"
            height={35}
            alt="telenet"
            onClick={handleClickHome}
            style={{
              marginTop: "0px",
            }}
          />
        </Link>
      </div>
      <div
        className="footer3"
        style={{
          height: "0.7rem",
        }}
      ></div>
      <iframe
        className="ZallooTourScreenTour"
        title="Zalloo Tour"
        src={iframeUrl}
        // style={{ border: "1px solid #252525", borderRadius: "25px" }}
        name="myiFrame"
        scrolling="no"
        frameBorder={1}
        marginHeight="100vh"
        marginWidth="100vh"
        allowFullScreen="Yes"
      />
    </div>
  );
};

export default ZallooTour;
