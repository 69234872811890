import "../App.css";
import Partners from "../components/partners";
import { TypeAnimation } from "react-type-animation";
import OurWork from "../components/OurWork";
import Contact from "../components/Contact";
// import PartnerCarousel from "../components/PartnerCarousel";

import { BrowserRouter as Router, Link } from "react-router-dom";

const isMobile = window.innerWidth < 768;

function LandingPage() {
  return (
    <main>
      <div id="myCarousel" className="carousel slide" data-bs-ride="carousel">
        {/* <div>
          <img className="block1" src="./images/test.png" alt="Bck" />
        </div> */}

        <div className="carousel-indicators" style={{ zIndex: 10 }}></div>
        <div className="carousel-inner">
          <div className="carousel-item active">
            <div className="container">
              <div className="carousel-caption text-end">
                <div>
                  <img
                    className="image1"
                    // src="./images/laptop6.webp"
                    src="./images/heroimage2.webp"
                    alt="Telenet"
                    width="600px"
                  />
                </div>
                <div className="heading1">
                  <h1 id="maintext">
                    YEARS IN <br />
                    INDUSTRY
                  </h1>
                  <p className="ptext" id="maintext2">
                    Telecommunications, Network &amp;
                    <br />
                  </p>
                  <TypeAnimation
                    className="ptext ptext2"
                    id="maintext2"
                    // Same String at the start will only be typed once, initially
                    sequence={[
                      "Software",
                      1500,
                      "Web Development",
                      1500,
                      "Web Design",
                      1500,
                    ]}
                    speed={30} // Custom Speed from 1-99 - Default Speed: 40
                    // style={{ fontWeight: "900" }}
                    wrapper="span" // Animation will be rendered as a <span>
                    repeat={Infinity} // Repeat this Animation Sequence infinitely
                  />

                  <p>
                    <a
                      className="btn btn-lg btn-primary btn-landing btnGradient"
                      href="#about"
                    >
                      Learn More
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <PartnerCarousel /> */}
      <div className="container marketing">
        <div className="row">
          <div className="col-lg-4">
            {/* <title>Placeholder</title> */}
            <div>
              <lord-icon
                src="https://cdn.lordicon.com/ybfcwnqv.json"
                trigger="loop"
                colors="primary:#121331,secondary:#1472ff"
                style={{ width: 250, height: 250 }}
                stroke={25}
                className="img-card"
                id="list1"
              ></lord-icon>

              <lord-icon
                src="https://cdn.lordicon.com/ybfcwnqv.json"
                trigger="loop"
                colors="primary:#c9c9c9,secondary:#1472ff"
                style={{ width: 250, height: 250 }}
                stroke={25}
                className="img-card"
                id="list1-1"
              ></lord-icon>
            </div>
            <br />
            <h2 className="fw-normal">Software Development</h2>
            <p
              data-aos="fade-up"
              data-aos-duration={1000}
              data-aos-once="false"
              className="supportText"
            >
              Satisfy all your needs, from design, functionality &amp; delivery
              of your perfect web or native application
              <br />
              <br />
            </p>
          </div>
          <div className="col-lg-4">
            {/* <title>Placeholder</title> */}
            <div>
              <lord-icon
                src="https://cdn.lordicon.com/xqfngtiz.json"
                trigger="loop"
                colors="primary:#121331,secondary:#1472ff"
                style={{ width: 250, height: 250 }}
                stroke={25}
                className="img-card"
                id="list2"
              ></lord-icon>

              <lord-icon
                src="https://cdn.lordicon.com/xqfngtiz.json"
                trigger="loop"
                colors="primary:#c9c9c9,secondary:#1472ff"
                style={{ width: 250, height: 250 }}
                stroke={25}
                className="img-card"
                id="list2-2"
              ></lord-icon>
            </div>
            <br />
            <h2 className="fw-normal">Web Design</h2>
            <p
              data-aos="fade-up"
              data-aos-duration={1500}
              data-aos-once="false"
              className="supportText"
            >
              Providing modern web designs that will make your brand stand out
              from the crowd
              <br />
              <br />
            </p>
          </div>
          <div className="col-lg-4">
            {/* <title>Placeholder</title> */}
            <div>
              <lord-icon
                src="https://cdn.lordicon.com/izmjkhuc.json"
                trigger="loop"
                colors="primary:#121331,secondary:#1472ff"
                style={{ width: 250, height: 250 }}
                stroke={25}
                className="img-card"
                id="list3"
              ></lord-icon>

              <lord-icon
                src="https://cdn.lordicon.com/izmjkhuc.json"
                trigger="loop"
                colors="primary:#c9c9c9,secondary:#1472ff"
                style={{ width: 250, height: 250 }}
                stroke={25}
                className="img-card"
                id="list3-3"
              ></lord-icon>
            </div>
            <br />
            <h2 className="fw-normal">Telecommunications</h2>
            <p
              data-aos="fade-up"
              data-aos-duration={2000}
              data-aos-once="false"
              className="supportText"
            >
              Providing services in Analog, Digital &amp; VoIP Telecommunication
              solutions.
              <br />
              <br />
            </p>
          </div>
        </div>
        <hr className="featurette-divider" id="webdew" />
        <div className="row featurette">
          <div className="col-md-7">
            <h2 className="featurette-heading fw-normal lh-1">
              Software Development
            </h2>
            <p
              className="lead"
              data-aos="fade-up"
              data-aos-duration={1500}
              data-aos-once="false"
            >
              We are able to independently and in cooperation with our close
              partners offer state of the art solutions and satisfy all your
              needs from design, functionality, delivery &amp; testing of your
              web applications or native applications.
              <br />
              Supporting technologies such as: JS, TS, React, React Native,
              Next.js, Flutter etc...
            </p>
          </div>
          <div className="col-md-5">
            {isMobile ? (
              <img
                className="telenetHeader2"
                // src="./images/101-4.svg"
                src="./images/mobile444.webp"
                alt="Software Development"
                width="100%"
                // height="100%"
                xmlns="http://www.w3.org/2000/svg"
                // role="img"
                aria-label="Placeholder: 500x500"
                preserveAspectRatio="xMidYMid slice"
                focusable="false"
                data-aos="fade-up"
                data-aos-duration={1000}
                data-aos-once="false"
              />
            ) : (
              <img
                className="telenetHeader2"
                // src="./images/101-4.svg"
                src="./images/444.webp"
                alt="Software Development"
                width="100%"
                // height="100%"
                xmlns="http://www.w3.org/2000/svg"
                // role="img"
                aria-label="Placeholder: 500x500"
                preserveAspectRatio="xMidYMid slice"
                focusable="false"
                data-aos="fade-up"
                data-aos-duration={1000}
                data-aos-once="false"
              />
            )}
            {/* <img
              className="telenetHeader2"
              // src="./images/101-4.svg"
              src="./images/444.png"
              alt="Software Development"
              width="100%"
              // height="100%"
              xmlns="http://www.w3.org/2000/svg"
              // role="img"
              aria-label="Placeholder: 500x500"
              preserveAspectRatio="xMidYMid slice"
              focusable="false"
              data-aos="fade-up"
              data-aos-duration={1000}
              data-aos-once="false"
            /> */}
            {/* <title>Placeholder</title> */}
            {/* <rect width="100%" height="100%" fill="#eee"></rect> */}
          </div>
        </div>
        <hr className="featurette-divider" id="webdesign" />
        <div className="row featurette">
          <div className="col-md-7 order-md-2">
            <h2 className="featurette-heading fw-normal lh-1">Web Design</h2>
            <p
              className="lead"
              data-aos="fade-up"
              data-aos-duration={1500}
              data-aos-once="false"
            >
              Providing modern web designs that will look and feel fast and
              responsive. <br />
              Using latest technologies we offer optimal solutions to all of
              your needs from design to functionality with unique designs and
              attention to detail.
              <br />
              Our design team works closely with you to understand your brand
              and create a customized web design that will not only be
              aesthetically pleasing, but also help you achieve your business
              goals.
            </p>
          </div>
          <div className="col-md-5 order-md-1">
            {isMobile ? (
              <img
                className="telenetHeader2"
                src="./images/mobileLaptopDesign.webp"
                // src="./images/555.png"
                alt="Web Design"
                width="100%"
                // height="100%"
                xmlns="http://www.w3.org/2000/svg"
                // role="img"
                aria-label="Placeholder: 500x500"
                preserveAspectRatio="xMidYMid slice"
                focusable="false"
                data-aos="fade-up"
                data-aos-duration={1000}
                data-aos-once="false"
              />
            ) : (
              <img
                className="telenetHeader2"
                src="./images/LaptopDesign.webp"
                // src="./images/555.png"
                alt="Web Design"
                width="100%"
                // height="100%"
                xmlns="http://www.w3.org/2000/svg"
                // role="img"
                aria-label="Placeholder: 500x500"
                preserveAspectRatio="xMidYMid slice"
                focusable="false"
                data-aos="fade-up"
                data-aos-duration={1000}
                data-aos-once="false"
              />
            )}
            {/* <img
              className="telenetHeader2"
              src="./images/LaptopDesign.png"
              // src="./images/555.png"
              alt="Web Design"
              width="100%"
              // height="100%"
              xmlns="http://www.w3.org/2000/svg"
              // role="img"
              aria-label="Placeholder: 500x500"
              preserveAspectRatio="xMidYMid slice"
              focusable="false"
              data-aos="fade-up"
              data-aos-duration={1000}
              data-aos-once="false"
            /> */}
            {/* <title>Placeholder</title> */}
            {/* <rect width="100%" height="100%" fill="#eee"></rect> */}
          </div>
        </div>
        <hr className="featurette-divider" id="tele" />
        <div className="row featurette">
          <div className="col-md-7">
            <h2 className="featurette-heading fw-normal lh-1">
              Telecommunications
            </h2>
            <p
              className="lead"
              data-aos="fade-up"
              data-aos-duration={1500}
              data-aos-once="false"
            >
              Telenet is active more than 20 years in Telecommunication
              industry.
              <br />
              With expertise ranging from analog Telecommunications all the way
              up to today's newest VoIP technologies, including Contact Center
              solutions.
              <br />
              We are able to independently and in cooperation with our close
              partners offer and satisfy all your needs from design,
              functionality, delivery of equipment, installation, and user
              training.
              <br />
            </p>
          </div>
          <div className="col-md-5">
            {isMobile ? (
              <img
                className="telenetHeader2"
                // src="./images/servers3.gif"
                src="./images/mobile333.webp"
                alt="Telecommunications"
                width="100%"
                // height="100%"
                xmlns="http://www.w3.org/2000/svg"
                aria-label="Placeholder: 500x500"
                preserveAspectRatio="xMidYMid slice"
                focusable="false"
                data-aos="fade-up"
                data-aos-duration={1000}
                data-aos-once="false"
              />
            ) : (
              <img
                className="telenetHeader2"
                // src="./images/servers3.gif"
                src="./images/333.webp"
                alt="Telecommunications"
                width="100%"
                // height="100%"
                xmlns="http://www.w3.org/2000/svg"
                aria-label="Placeholder: 500x500"
                preserveAspectRatio="xMidYMid slice"
                focusable="false"
                data-aos="fade-up"
                data-aos-duration={1000}
                data-aos-once="false"
              />
            )}
          </div>
        </div>
        <hr className="featurette-divider" id="about" />
        <div className="row featurette">
          <div className="col-md-7 order-md-2">
            <h2 className="featurette-heading fw-normal lh-1">About Us</h2>
            <p
              className="lead"
              data-aos="fade-up"
              data-aos-duration={1500}
              data-aos-once="false"
            >
              Telenet is Telecommunications, Network &amp; Software company
              based in Split, Croatia, founded in 2001. We provide various
              services such as Web Design, Web Development, System
              Configuration, QA Testing, VoIP Solutions, Network support, Video
              Surveillance solutions, Outsourcing services and other
              Telecommunication services.
              <br />
              <br />
              Telenet is certified representative for Siemens, Unify, R&amp;M
              and Panduit. <br />
            </p>
            <div
              data-aos="fade-up"
              data-aos-duration={1500}
              data-aos-once="false"
            >
              <Link
                className="btn btn-lg btn-primary btn-landing btnGradient findOutBtn"
                to="/about"
              >
                Find out more
              </Link>
            </div>
          </div>
          <div className="col-md-5 order-md-1">
            {isMobile ? (
              <img
                className="telenetHeader2"
                src="./images/mobile102-42.webp"
                // src="./images/102-4.png"
                alt="About Telenet"
                id="split"
                width="100%"
                // height="100%"
                xmlns="http://www.w3.org/2000/svg"
                aria-label="Placeholder: 500x500"
                preserveAspectRatio="xMidYMid slice"
                focusable="false"
                data-aos="fade-up"
                data-aos-duration={1000}
                data-aos-once="false"
              />
            ) : (
              <img
                className="telenetHeader2"
                src="./images/102-42.webp"
                // src="./images/102-4.png"
                alt="About Telenet"
                id="split"
                width="100%"
                // height="100%"
                xmlns="http://www.w3.org/2000/svg"
                aria-label="Placeholder: 500x500"
                preserveAspectRatio="xMidYMid slice"
                focusable="false"
                data-aos="fade-up"
                data-aos-duration={1000}
                data-aos-once="false"
              />
            )}
          </div>
        </div>
        <hr className="featurette-divider" />
        <OurWork />
        <hr className="featurette-divider" id="contact" />
        <div className="row featurette">
          <div className="col-md-7">
            <h2 className="featurette-heading fw-normal lh-1">
              {/* Reach Out <span className="text-muted" /> */}
            </h2>
            <div
              className="lead"
              // data-aos="fade-up"
              // data-aos-duration={1200}
              // data-aos-once="false"
            >
              {/* Don't Hesitate To Contact Us! <br />
              Reach out:
              <br />
              Phone: +385-95-851-6594
              <br />
              Contact us at:
              <a className="lsmail" href="mailto:info@telenet.agency">
                info@telenet.agency
              </a>
              <br />
              Matice Hrvatske 1, Split, Croatia <br />
              Find us on
              <a
                href="https://www.linkedin.com/company/telenet-hr"
                className="in"
              >
                <img
                  className="in"
                  src="./images/in.png"
                  width={20}
                  alt="linked in"
                />
              </a>
              or
              <a href="https://www.instagram.com/telenet.hr/" className="in">
                <img
                  className="instagram"
                  src="./images/insta.png"
                  width={15}
                  alt="instagram"
                />
              </a> */}
              <h4
                data-aos="fade-up"
                data-aos-duration={800}
                data-aos-once="false"
              >
                Don't Hesitate To Contact Us!{" "}
              </h4>{" "}
              <br />
              <div
                className="contactText"
                data-aos="fade-up"
                data-aos-duration={1000}
                data-aos-once="false"
              >
                <img
                  src="./images/phoneBlue.png"
                  alt="email"
                  width={25}
                  className="conactIcon"
                />
                +385-95-851-6594
              </div>
              <br />
              <div
                data-aos="fade-up"
                data-aos-duration={1200}
                data-aos-once="false"
              >
                <img src="./images/emailBlue.png" alt="email" width={25} />
                <a className="lsmailContact" href="mailto:info@telenet.agency">
                  info@telenet.agency
                </a>
              </div>
              <br />
              <div
                data-aos="fade-up"
                data-aos-duration={1400}
                data-aos-once="false"
              >
                <img
                  src="./images/locationBlue.png"
                  alt="email"
                  width={25}
                  className="conactIcon"
                />
                Matice Hrvatske 1, Split, Croatia <br />
              </div>
              <div
                className="landingSocialsDiv"
                data-aos="fade-up"
                data-aos-duration={1600}
                data-aos-once="false"
              >
                <img
                  src="./images/webBlue.png"
                  alt="email"
                  width={25}
                  className="conactIcon"
                />
                Find us on
                <a
                  href="https://www.linkedin.com/company/telenet-hr"
                  className="in"
                >
                  <img
                    className="in"
                    src="./images/in.png"
                    width={20}
                    alt="linked in"
                  />
                </a>
                or
                <a href="https://www.instagram.com/telenet.hr/" className="in">
                  <img
                    className="instagram"
                    src="./images/insta.png"
                    width={15}
                    alt="instagram"
                  />
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-5">
            <div className="reachoutarea">
              <Contact />
              {/* {isMobile ? (
                <img
                  className="telenetHeader2"
                  src="./images/mobile10-2.png"
                  alt="Contact Us"
                  id="telenetcontact"
                  width="100%"
                  xmlns="http://www.w3.org/2000/svg"
                  // role="img"
                  aria-label="Placeholder: 500x500"
                  preserveAspectRatio="xMidYMid slice"
                  focusable="false"
                  data-aos="fade-right"
                  data-aos-duration={1000}
                  data-aos-once="false"
                />
              ) : (
                <img
                  className="telenetHeader2"
                  src="./images/10-2.png"
                  alt="Contact Us"
                  id="telenetcontact"
                  width="100%"
                  xmlns="http://www.w3.org/2000/svg"
                  // role="img"
                  aria-label="Placeholder: 500x500"
                  preserveAspectRatio="xMidYMid slice"
                  focusable="false"
                  data-aos="fade-right"
                  data-aos-duration={1000}
                  data-aos-once="false"
                />
              )} */}
              {/* <img
                className="telenetHeader2"
                src="./images/10-2.png"
                alt="Contact Us"
                id="telenetcontact"
                width="100%"
                xmlns="http://www.w3.org/2000/svg"
                // role="img"
                aria-label="Placeholder: 500x500"
                preserveAspectRatio="xMidYMid slice"
                focusable="false"
                data-aos="fade-right"
                data-aos-duration={1000}
                data-aos-once="false"
              /> */}
              {/* <div className="reachoutbtn">
                <a
                  className="btn btn-lg btn-primary"
                  href="mailto:info@telenet.agency"
                >
                  Reach Out
                </a>
              </div> */}
            </div>
            {/* <title>Placeholder</title> */}
            {/* <rect width="100%" height="100%" fill="#eee"></rect> */}
          </div>
        </div>
      </div>

      <Partners />
    </main>
  );
}

export default LandingPage;
