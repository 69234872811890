import "../App.css";
import Partners from "../components/partners";

import { BrowserRouter as Router, Link } from "react-router-dom";
import ZallooTour from "./ZallooTour";

import { TypeAnimation } from "react-type-animation";

function Tours() {
  const toursData = [
    {
      name: "Villa Biancomar",
      url: "https://tours.zalloo.app/villa-biancomar/",
    },
    {
      name: "Villa Trogir",
      url: "https://tours.zalloo.app/villa-trogir/",
    },
    {
      name: "Luxury Apartment Maris",
      url: "https://tours.zalloo.app/luxury-apartment-maris/",
    },
  ];

  return (
    <>
      <div
        id="myCarousel"
        className="carousel2 carousel slide carouselzalloo"
        data-bs-ride="carousel"
      >
        <div className="carousel-indicators" style={{ zIndex: 10 }}></div>
        <div className="carousel-inner">
          <div className="carousel-item active">
            <div className="container">
              <div className="carousel-caption text-end">
                <div>
                  <img
                    className="image1"
                    src="./images/zallooLanding.webp"
                    alt="3D Object"
                    width="600px"
                  />
                </div>
                <div className="heading1" id="maintextZalloo">
                  <h1 id="maintextTours">
                    VIRTUAL
                    <br />
                    TOURS
                  </h1>

                  <div className="ptext ptext3">We can</div>
                  <TypeAnimation
                    className="ptext ptext2"
                    id="maintext"
                    sequence={[
                      "Photograph",
                      1500,
                      "Develop",
                      1500,
                      "Deploy",
                      1500,
                      "Support",
                      1800,
                    ]}
                    speed={30}
                    wrapper="span"
                    repeat={Infinity}
                  />
                  <div className="ptext">your virtual tour!</div>
                  <p>
                    <a
                      className="btn btn-lg btn-primary btn-landing btnGradient"
                      href="#about"
                    >
                      Learn More
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container marketing">
        <div className="zallo">
          <h2 className="zalloHeading">In partnership with</h2>
          <img
            src="./images/zalloo2.png"
            alt="Zalloo Tours"
            className=" part-p"
            id="Zalloo"
            width={120}
          />
        </div>
        <div className="row featurette zalloFeaturette">
          <div className="col-md-7">
            <h2
              className="featurette-heading fw-normal lh-1 zallooHeading"
              data-aos="fade-right"
              data-aos-duration={900}
              data-aos-once="false"
            >
              Villa Biancomar
            </h2>
            <Link
              className="btn btn-lg btn-primary btn-landing btnGradient zallooBtnMarginBottom"
              // href="https://tours.zalloo.app/villa-biancomar/"
              // to="/zalootour"
              // to="/zallooTour"
              to="/villaBiancomar"
              state={{ iframeUrl: toursData[0].url }}
            >
              View full tour
            </Link>
            <p
              className="lead toursText"
              data-aos="fade-right"
              data-aos-duration={1600}
              data-aos-once="false"
            >
              We conducted a comprehensive 360° photography session using 39
              strategically chosen viewpoints. Post-production work involved
              meticulous editing and stitching to create a precise virtual tour,
              accurately representing the property's features and spatial
              arrangements.
            </p>
          </div>
          <div className="col-md-5 zalloVillaBox">
            <Link to="/villaBiancomar" state={{ iframeUrl: toursData[0].url }}>
              <div className="zallooTourGradientBox">
                <img
                  className="telenetHeader2"
                  // src="./images/101-4.svg"
                  src="./images/biancomar.webp"
                  alt="Villa Biancomar"
                  width="100%"
                  // height="100%"
                  xmlns="http://www.w3.org/2000/svg"
                  // role="img"
                  aria-label="Placeholder: 500x500"
                  preserveAspectRatio="xMidYMid slice"
                  focusable="false"
                  // data-aos="fade-up"
                  // data-aos-duration={1000}
                  // data-aos-once="false"
                  style={{
                    borderRadius: "25px",
                    boxShadow:
                      "rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px",
                  }}
                />
              </div>
            </Link>
          </div>
        </div>

        <hr className="featurette-divider" />

        <div className="row featurette zalloFeaturette">
          <div className="col-md-7 order-md-2">
            <h2
              className="featurette-heading fw-normal lh-1 zallooHeading"
              data-aos="fade-down"
              data-aos-duration={900}
              data-aos-once="false"
            >
              Villa Trogir
            </h2>
            <Link
              className="btn btn-lg btn-primary btn-landing btnGradient zallooBtnMarginBottom"
              // href="https://tours.zalloo.app/villa-trogir/"
              // to="/zalootour"
              // to="/zallooTour"
              to="/villaTrogir"
              state={{ iframeUrl: toursData[1].url }}
            >
              View full tour
            </Link>

            <p
              className="toursText lead toursText"
              data-aos="fade-right"
              data-aos-duration={1600}
              data-aos-once="false"
            >
              Our thorough 360° photographic approach captured this villa from
              43 different viewpoints. Following meticulous editing and image
              stitching, a detailed virtual tour was produced, accurately
              showcasing the villa's layout and key architectural features. This
              tour provides viewers with an authentic and comprehensive insight
              into the property.
              {/* This is a visual representation of how your propery might look
              covered with our 360 tour */}
            </p>
          </div>
          <div className="col-md-5 order-md-1 zalloVillaBox">
            <Link to="/villaTrogir" state={{ iframeUrl: toursData[1].url }}>
              <div className="zallooTourGradientBox">
                <img
                  className="telenetHeader2"
                  // src="./images/101-4.svg"
                  src="./images/trogir.webp"
                  alt="Software Development"
                  width="100%"
                  // height="100%"
                  xmlns="http://www.w3.org/2000/svg"
                  // role="img"
                  aria-label="Placeholder: 500x500"
                  preserveAspectRatio="xMidYMid slice"
                  focusable="false"
                  // data-aos="fade-up"
                  // data-aos-duration={1000}
                  // data-aos-once="false"
                  style={{
                    borderRadius: "25px",
                    boxShadow:
                      "rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px",
                  }}
                />
              </div>
            </Link>
          </div>
        </div>

        <hr className="featurette-divider" />

        <div className="row featurette zalloFeaturette">
          <div className="col-md-7">
            <h2
              className="featurette-heading fw-normal lh-1 zallooHeading"
              data-aos="fade-right"
              data-aos-duration={900}
              data-aos-once="false"
            >
              Luxury Apartment Maris
            </h2>
            <Link
              className="btn btn-lg btn-primary btn-landing btnGradient zallooBtnMarginBottom"
              // href="https://tours.zalloo.app/luxury-apartment-maris/"
              // to="/zalootour"
              // to="/zallooTour"
              to="/maris"
              state={{ iframeUrl: toursData[2].url }}
            >
              View full tour
            </Link>
            <p
              className="lead toursText"
              data-aos="fade-right"
              data-aos-duration={1600}
              data-aos-once="false"
            >
              Our team captured the apartment's features through 14 distinct
              viewpoints. The carefully stitched and edited images culminated in
              a comprehensive 360° virtual tour, providing an accurate depiction
              of the apartment's layout and amenities.
            </p>
          </div>
          <div className="col-md-5 zalloVillaBox">
            <Link to="/maris" state={{ iframeUrl: toursData[2].url }}>
              <div className="zallooTourGradientBox">
                <img
                  className="telenetHeader2"
                  // src="./images/101-4.svg"
                  src="./images/maris.webp"
                  alt=" Luxury Apartment Maris"
                  width="100%"
                  // height="100%"
                  xmlns="http://www.w3.org/2000/svg"
                  // role="img"
                  aria-label="Placeholder: 500x500"
                  preserveAspectRatio="xMidYMid slice"
                  focusable="false"
                  // data-aos="fade-up"
                  // data-aos-duration={1000}
                  // data-aos-once="false"
                  style={{
                    borderRadius: "25px",
                    boxShadow:
                      "rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px",
                  }}
                />
              </div>
            </Link>
          </div>
        </div>

        {/* <div className="col-md-5-tours">
            <iframe
              className="villaBox"
              title="Villa Trogir"
              src="https://tours.zalloo.app/villa-trogir/"
              style={{ border: "1px solid #252525", borderRadius: "25px" }}
              name="myiFrame"
              scrolling="no"
              frameBorder={1}
              marginHeight="0px"
              marginWidth="0px"
              height="500px"
              // height={"100%"}
              width="100%"
              allowFullScreen="Yes"
            />
          </div> */}

        {/* <iframe
          className="villaBox"
          title="Villa Maris"
          src="https://tours.zalloo.app/luxury-apartment-maris/"
          style={{ border: "1px solid #252525", borderRadius: "25px" }}
          name="myiFrame"
          scrolling="no"
          frameBorder={1}
          marginHeight="0px"
          marginWidth="0px"
          height="500px"
          // height={"100%"}
          width="100%"
          allowFullScreen="Yes"
        /> */}

        <hr className="featurette-divider" id="about" />
        <div className="row featurette">
          <div className="col-md-7 order-md-2">
            <h2 className="featurette-heading fw-normal lh-1">About Zalloo</h2>
            <p
              className="lead"
              data-aos="fade-up"
              data-aos-duration={1500}
              data-aos-once="false"
            >
              We are a virtual tour company that specializes in creating
              immersive 360° experiences for a wide range of properties,
              including apartments, villas, and restaurants. Our mission is to
              provide our clients with high-quality virtual tours that help them
              showcase their properties and connect with potential customers.{" "}
              <br /> We work closely with our clients to understand their needs
              and preferences, and we always strive to exceed their
              expectations.
              <br />
              visit us on:
              <a className="zallooHref" href="https://tours.zalloo.app/">
                www.zalloo.app
              </a>
            </p>
          </div>
          <div className="col-md-5 order-md-1">
            <img
              className="telenetHeader2"
              src="./images/zalloo.png"
              alt="About Telenet"
              id="split"
              width="100%"
              // height="100%"
              xmlns="http://www.w3.org/2000/svg"
              aria-label="Placeholder: 500x500"
              preserveAspectRatio="xMidYMid slice"
              focusable="false"
              data-aos="fade-up"
              data-aos-duration={1000}
              data-aos-once="false"
            />
          </div>
        </div>
      </div>
      <Partners />
    </>
  );
}

export default Tours;
