import "../App.css";
import DocumentationENG from "../components/documentationENG";
import Partners from "../components/partners";

function EngComponent() {
  return (
    <main>
      <h2 className="ponudah2">Telecommunication Equipment</h2>
      <div className="container marketing">
        <div className="row">
          <div className="col-lg-4">
            <img
              src="./images/ENG16.png"
              alt="Telecommunications"
              className="ponuda"
            />
          </div>
          <div className="col-lg-4">
            <img
              src="./images/ENG17.png"
              alt="Telecommunications"
              className="ponuda"
            />
          </div>
          <div className="col-lg-4">
            <img
              src="./images/ENG18.png"
              alt="Telecommunications"
              className="ponuda"
            />
          </div>
          <div className="col-lg-4">
            <img
              src="./images/ENG1.png"
              alt="Telecommunications"
              className="ponuda"
            />
          </div>
          <div className="col-lg-4">
            <title>Placeholder</title>

            <img
              src="./images/ENG2.png"
              alt="Telecommunications"
              className="ponuda"
            />
          </div>
          <div className="col-lg-4">
            <title>Placeholder</title>

            <img
              src="./images/ENG3.png"
              alt="Telecommunications"
              className="ponuda"
            />
          </div>
          <div className="col-lg-4">
            <title>Placeholder</title>

            <img
              src="./images/ENG4.png"
              alt="Telecommunications"
              className="ponuda"
            />
          </div>
          <div className="col-lg-4">
            <title>Placeholder</title>

            <img
              src="./images/ENG5.png"
              alt="Telecommunications"
              className="ponuda"
            />
          </div>
          <div className="col-lg-4">
            <title>Placeholder</title>

            <img
              src="./images/ENG6.png"
              alt="Telecommunications"
              className="ponuda"
            />
          </div>
          <div className="col-lg-4">
            <title>Placeholder</title>

            <img
              src="./images/ENG7.png"
              alt="Telecommunications"
              className="ponuda"
            />
          </div>
          <div className="col-lg-4">
            <title>Placeholder</title>

            <img
              src="./images/ENG8.png"
              alt="Telecommunications"
              className="ponuda"
            />
          </div>
          <div className="col-lg-4">
            <title>Placeholder</title>

            <img
              src="./images/ENG9.png"
              alt="Telecommunications"
              className="ponuda"
            />
          </div>
          <div className="col-lg-4">
            <title>Placeholder</title>

            <img
              src="./images/ENG10.png"
              alt="Telecommunications"
              className="ponuda"
            />
          </div>
          <div className="col-lg-4">
            <title>Placeholder</title>

            <img
              src="./images/ENG11.png"
              alt="Telecommunications"
              className="ponuda"
            />
          </div>
          <div className="col-lg-4">
            <title>Placeholder</title>

            <img
              src="./images/ENG12.png"
              alt="Telecommunications"
              className="ponuda"
            />
          </div>
          <div className="col-lg-4">
            <title>Placeholder</title>

            <img
              src="./images/ENG13.png"
              alt="Telecommunications"
              className="ponuda"
            />
          </div>
          <div className="col-lg-4">
            <title>Placeholder</title>

            <img
              src="./images/ponuda14.png"
              alt="Telecommunications"
              className="ponuda"
            />
          </div>
          <div className="col-lg-4">
            <title>Placeholder</title>

            <img
              src="./images/ENG15.png"
              alt="Telecommunications"
              className="ponuda"
            />
          </div>
        </div>
        <div className="documentationSection">
          <DocumentationENG />
        </div>
      </div>
      <section className="ponuda2 lead" id="uvijeti">
        <h2 className="ponudah2">Terms & Conditions</h2>
        <p>
          Delivery time: <br />
          7 working days from receiving the order. <br />
          <br />
          Delivery conditions: <br />
          The delivery conditions refer to the FCO buyer parity. <br />
          <br />
          Payment: <br />
          30 days after delivery. <br />
          <br />
          Warranty terms: <br />
          The warranty includes repair or replacement of all parts during 1
          year. The warranty excludes the replacement of parts damaged by
          excessively high voltage on the public electric and telephone network.{" "}
          <br />
          <br />
        </p>
        <p className="ponuda-footer-info">
          We hope that we have contributed to improving the quality of
          communication within your company and thus the efficiency of your
          business. We remain open for further contact. <br />
          <br />
        </p>
        <p>
          Contact Us: <br />
          +385-91-540-3544 <br />
          <a className="lsmail" href="mailto:telenet.split@gmail.com">
            telenet.split@gmail.com
          </a>
        </p>
      </section>
      <Partners />
    </main>
  );
}

export default EngComponent;
