import "../App.css";
import CounterAnimation from "../components/Counter";
// import ImageAnimation from "../components/LogoLoading";

function LoadingScreen() {
  return (
    <div className="loadingScreen">
      <img className="loadingAnimation" src="./images/10-2.png" alt="telenet" />

      <div className="loading">
        <div className="line-box">
          <div className="line"></div>
        </div>
      </div>
      <div className="percentageNum">
        {/* <div className="counter"></div> */}
        <CounterAnimation className="counter2Sec" />
        <div className="percentageNumPercentage">%</div>
      </div>
    </div>
  );
}

export default LoadingScreen;
