import "../App.css";

function DocumentationENG() {
  return (
    <>
      <h3>Documentation</h3>
      <hr className="featurette-divider3" />
      <div className="downloadableLink">
        <div>Openscape CP G2</div>
        <a href="https://wiki.unify.com/wiki/OpenScape_Desk_Phone_CP_G2">
          <img
            src="./images/downloadicon.png"
            alt="download"
            className="documentationLink"
          />
        </a>
      </div>
      <hr className="featurette-divider3" />
      <div className="downloadableLink">
        <div>Documentation for CP series</div>
        <a href="https://unify.com/en/?nx_doc_id=f2783a51-8395-4377-b080-c9b5870d1883&type=pdf&action=view">
          <img
            src="./images/downloadicon.png"
            alt="download"
            className="documentationLink"
          />
        </a>
      </div>

      <hr className="featurette-divider3" />
      <div className="downloadableLink">
        <div>OpenStage 20 phones</div>
        <a href="https://held.hr/upute/user-manual-openstage-20-t.pdf">
          <img
            src="./images/downloadicon.png"
            alt="download"
            className="documentationLink"
          />
        </a>
      </div>
      <hr className="featurette-divider3" />
      <div className="downloadableLink">
        <div>OpenStage 40 phones</div>
        <a href="https://held.hr/upute/user-manual-openstage-40-t.pdf">
          <img
            src="./images/downloadicon.png"
            alt="download"
            className="documentationLink"
          />
        </a>
      </div>
      <hr className="featurette-divider3" />
      <div className="downloadableLink">
        <div>Gigaset C575 phones</div>
        <a href="https://gse.gigaset.com/fileadmin/gigaset/images/CustomerCare/Manuals/C5x/C575HX/A31008-M2967-R101-1-4N19_en_INT.pdf">
          <img
            src="./images/downloadicon.png"
            alt="download"
            className="documentationLink"
          />
        </a>
      </div>
      <hr className="featurette-divider3" />
    </>
  );
}

export default DocumentationENG;
