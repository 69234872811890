import "../App.css";
// import Documentation from "../components/documentation";
// import Partners from "../components/partners";
import React, { useState } from "react";
import EngComponent from "../components/EngComponent";
import CroComponent from "../components/CroComponent";

function ProductScreen() {
  const [language, setLanguage] = useState("ENG");

  const handleCroClick = () => {
    setLanguage("CRO");
    setIsCRO(true);
  };

  const handleEngClick = () => {
    setLanguage("ENG");
    setIsCRO(false);
  };

  const [isCRO, setIsCRO] = useState(false);

  return (
    <div>
      <div className="CroEngSelector">
        <div className="LanguageContainer">
          <img
            className="LanguageBTN active"
            src="./images/CRO.png"
            onClick={handleCroClick}
            alt="CRO"
            style={{
              filter: isCRO ? "brightness(100%)" : "brightness(50%)",
            }}
          />
          <p className="LanguageContainerText">Cro</p>
        </div>

        <div className="LanguageContainer">
          <img
            className="LanguageBTN active"
            src="./images/ENG.png"
            onClick={handleEngClick}
            alt="ENG"
            style={{
              filter: isCRO ? "brightness(50%)" : "brightness(100%)",
            }}
          />
          <p className="LanguageContainerText">Eng</p>
        </div>
      </div>
      {language === "CRO" ? <CroComponent /> : <EngComponent />}
    </div>
  );
}

export default ProductScreen;
