import { useRef, useEffect } from "react";
import { TweenMax } from "gsap";

function CounterAnimation() {
  const counterRef = useRef(null);

  useEffect(() => {
    TweenMax.to(counterRef.current, 1.4, {
      innerHTML: 100,
      roundProps: "innerHTML",
      ease: "Power2.easeOut",
    });
  }, []);

  return (
    <div className="counter" ref={counterRef}>
      1
    </div>
  );
}

export default CounterAnimation;
